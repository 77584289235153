import { styled } from "../../../../stitches.config";

import Heading from "../../../../components/Heading";
import Icon from "../../../../components/Icon";
import StatTile from "../../../../components/StatTile";
import { User } from "../../../../hooks/useUser";
import type { Reward } from "../../../../types";
import useI18n from "../../../../hooks/useI18n";
import { EmptyStatTile } from "../../../../components/StatTile/StatTile";

const Wrapper = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "@lg": {
    alignItems: "flex-start",
  },
});

const Grid = styled("div", {
  width: "100%",
  overflowX: "auto",
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "minmax(170px, 1fr)",
  gridColumnGap: "6px",
  gridRowGap: "6px",
  paddingTop: 20,
  paddingBottom: 10,

  "@lg": {
    paddingBottom: 20,
    gridTemplateRows: "1fr",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
});

type StatsProps = {
  user?: User;
  nextReward?: Reward;
  rewardHistory?: Reward[];
};

export const EmptyStats = () => {
  const { t } = useI18n();

  return (
    <Wrapper>
      <Heading level={1}>{t("Your Stats")}</Heading>

      <Grid>
        <EmptyStatTile color="purple" />
        <EmptyStatTile color="skyblue" />
        <EmptyStatTile color="blue" />
        <EmptyStatTile color="green" />
      </Grid>
    </Wrapper>
  );
};

const Stats = ({ user, nextReward, rewardHistory = [] }: StatsProps) => {
  const { t } = useI18n();

  if (!user || !nextReward) return null;

  const isKycVerified = user.kycStatus === "verified";

  const paidOut = rewardHistory.reduce(
    (acc, reward) => (reward.paidOutAt ? acc + reward.amount : acc),
    0
  );

  return (
    <Wrapper>
      <Heading level={1}>{t("Your Stats")}</Heading>

      <Grid>
        <StatTile
          title={t("Friends Joined")}
          value={user.refereeCount}
          color="purple"
        />

        {!!isKycVerified && (
          <StatTile
            title={t("Paid Out")}
            value={paidOut}
            unit="USDC"
            color="skyblue"
            icon={<Icon type="usdc" />}
          />
        )}

        <StatTile
          title={isKycVerified ? t("Pending Rewards") : t("Earned Reward")}
          value={nextReward.amount}
          unit="USDC"
          color="blue"
          icon={<Icon type="usdc" />}
        />

        <StatTile
          title={t("Next Payout")}
          subtitle={t("No upcoming payouts")}
          value={null}
          unit="Days"
          color="green"
          icon={<Icon type="usdc" />}
        />
      </Grid>
    </Wrapper>
  );
};

export default Stats;
