import { styled } from "../../stitches.config";

import Hero from "./components/Hero";
import Stats from "./components/Stats";
import Rewards from "./components/Rewards";
import CodeBanner from "./components/CodeBanner";
import useProfile from "../../hooks/useProfile";
import usePollFetch from "../../hooks/usePollFetch";
import { IS_CODE_BANNER_ENABLED } from "../../constants";

const Wrapper = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Home = () => {
  const { profile } = useProfile();
  const {
    user: { user },
    reward: { nextReward, loading: nextRewardLoading },
    history: { rewardHistory, loading: rewardHistoryLoading },
  } = usePollFetch();

  return (
    <Wrapper>
      <Hero user={user} profile={profile} />

      <Stats
        user={user}
        nextReward={nextReward}
        rewardHistory={rewardHistory}
      />

      {IS_CODE_BANNER_ENABLED && <CodeBanner code={user?.referralCode} />}

      {user?.kycStatus === "verified" && (
        <Rewards
          nextReward={nextReward}
          rewardHistory={rewardHistory}
          loading={nextRewardLoading || rewardHistoryLoading}
        />
      )}
    </Wrapper>
  );
};

export default Home;
